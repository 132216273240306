export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const preload = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = require(`@/assets/${src}`);
  });

export const preloadAll = (srcs) => Promise.all(srcs.map(preload));

const res4path = {
  "/": [
    "flags/en.png",
    "flags/ro.png",
    "backgrounds/fill.webp",
    "backgrounds/left.webp",
    "backgrounds/top.webp",
  ],
  "/invitation": [
    "backgrounds/right.webp",
    "backgrounds/bottom.webp",
    "others/inv_en.webp",
    "others/inv_ro.webp",
    "others/header.png",
  ],
};

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

export const preloadForPath = (path) => preloadAll(res4path[path]);
