export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading"])},
        "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loaded"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saved"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
      },
      "ro": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se încarcă"])},
        "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["încărcat"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvat"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eroare"])}
      }
    }
  })
}