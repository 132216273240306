export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menu"])},
        "invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invitation"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event info"])},
        "RSVP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmation"])},
        "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendees"])},
        "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])}
      },
      "ro": {
        "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meniu"])},
        "invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invitație"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informații eveniment"])},
        "RSVP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmare"])},
        "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participanți"])},
        "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])}
      }
    }
  })
}