import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./services/router.js";
import { preloadForPath } from "@/services/common.js";

import uk from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
uk.use(Icons);

window.UIkit = uk;

const i18n = createI18n({
  locale: (
    localStorage.locale ||
    navigator.language ||
    navigator.userLanguage ||
    "en"
  ).substr(0, 2),
  fallbackLocale: "en",
});

preloadForPath("/").then(() => {
  const app = createApp(App);
  app.config.unwrapInjectedRef = true;
  app.use(router);
  app.use(i18n);
  app.mount("#app");
});
