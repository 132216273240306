export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your invitation code below to access the website."])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CODE"])},
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER"])}
      },
      "ro": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bine ați venit!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți codul de invitație mai jos pentru a accesa site-ul."])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COD"])},
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTRĂ"])}
      }
    }
  })
}