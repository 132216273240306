<template>
  <template v-if="fullscreenPage === undefined"> LOADING >>> </template>
  <img
    v-for="lang in ['en', 'ro']"
    :key="lang"
    class="flag uk-position-top-right uk-link"
    :class="{ 'uk-invisible': $i18n.locale === lang }"
    :src="require(`@/assets/flags/${lang}.png`)"
    @click="changeLocale()"
  />
  <header
    v-if="fullscreenPage !== undefined"
    :class="{ fullscreenPage: fullscreenPage }"
  >
    <img src="@/assets/others/header.png" @dblclick="goToAdmin()" />

    <div class="uk-position-bottom-center">
      <a
        class="menu-button uk-text-uppercase uk-link-reset"
        @click="menuActive = !menuActive"
        >{{ $t("menu") }}</a
      >
    </div>
  </header>

  <router-view v-slot="{ Component, route }">
    <transition
      :name="route.meta.transition"
      :enter-active-class="route.meta.enterTransition"
      :leave-active-class="route.meta.leaveTransition"
    >
      <component
        :is="Component"
        id="content"
        :class="{ fullscreenPage: fullscreenPage }"
        class="animate__animated"
      />
    </transition>
  </router-view>

  <div
    v-if="fullscreenPage !== undefined"
    id="nav"
    :class="{ active: menuActive, fullscreenPage: fullscreenPage }"
  >
    <div
      class="uk-height-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-wrap uk-flex-wrap-around"
      @click="menuActive = false"
    >
      <div>
        <router-link to="/invitation">{{ $t("invitation") }}</router-link>
        <router-link to="/event">{{ $t("event") }}</router-link>
        <router-link to="/rsvp/self">{{ $t("RSVP") }}</router-link>
      </div>

      <div>
        <router-link to="/attendees">{{ $t("attendees") }}</router-link>
        <router-link to="/media">{{ $t("media") }}</router-link>
        <router-link to="/contact">{{ $t("contact") }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import Auth from "@/services/Auth.js";

export default {
  name: "App",
  data() {
    return {
      auth: Auth.state,
      menuActive: false,
    };
  },
  computed: {
    fullscreenPage() {
      return this.$route.meta.fullscreen;
    },
  },
  provide() {
    return {
      isAdmin: computed(() => this.auth.admin),
    };
  },
  watch: {
    "auth.logged"(logged) {
      if (!logged) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.$i18n.locale == "en" ? "ro" : "en";
      window.localStorage.locale = this.$i18n.locale;
    },
    goToAdmin() {
      if (!this.auth.admin) return;
      if (history.state.back == "/admin") return history.back();
      this.$router.push("/admin");
    },
  },
};
</script>

<style lang="less">
@import "../node_modules/video.js/dist/video-js.css";

.video-js {
  // This class is added to the video.js element by the plugin by default.
  &.vjs-http-source-selector {
    display: block;
  }
}

@import "../node_modules/uikit/src/less/uikit.theme.less";
@import "assets/css/variables.less";
@import "assets/css/hexagons.css";
@import "assets/css/transitions.less";
@import "assets/css/global.less";

@top: 20vh;

#content.fullscreenPage {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: @bg;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: @crem;
  text-align: center;
  height: @top;
  //min-height: 150px;
  border-bottom: 2px solid @bg;

  img {
    height: 100%;
    float: right;
    margin-right: calc(50% + 40px);
  }

  .menu-button {
    display: block;
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 15;
    background: white;
    top: 41px;
    border: 2px solid @gri;
    border-radius: 100%;
    line-height: 80px;

    @media (min-width: @breakpoint-medium) {
      display: none;
    }
  }
}

#content {
  animation-fill-mode: both;
  will-change: transform;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  top: calc(@top + 20px);
  left: 0;
  right: 0;

  @media (min-width: @breakpoint-medium) {
    right: 300px;
  }

  //background: white;
}

@font-face {
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  src: url("https://weloveiconfonts.com/api/fonts/fontawesome/fontawesome-webfont.eot");
  src:
    url("https://weloveiconfonts.com/api/fonts/fontawesome/fontawesome-webfont.eot?#iefix")
      format("eot"),
    url("https://weloveiconfonts.com/api/fonts/fontawesome/fontawesome-webfont.woff")
      format("woff"),
    url("https://weloveiconfonts.com/api/fonts/fontawesome/fontawesome-webfont.ttf")
      format("truetype"),
    url("https://weloveiconfonts.com/api/fonts/fontawesome/fontawesome-webfont.svg#FontAwesomeRegular")
      format("svg");
}

#nav {
  //display: none;
  position: fixed;
  top: @top;
  bottom: -20vh;
  padding-bottom: 20vh;
  right: 0;
  width: 100%;
  z-index: 5;
  background: @bg;
  opacity: 0;
  transition: opacity 0.25s linear;
  pointer-events: none;

  @media (min-width: 960px) {
    background: transparent;
  }

  &.active {
    //display: block;
    opacity: 1;
    pointer-events: auto;
  }

  @media (min-width: 960px) {
    width: 300px;
    //display: block;
    pointer-events: auto;
    opacity: 1;
  }

  a {
    display: block;
    width: 40vw;
    min-width: 210px;
    max-width: 230px;
    background: @grid;
    font-weight: bold;
    color: @bg;
    padding: 5px;
    border-radius: 40px;
    line-height: 30px;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 10px;
    overflow: hidden;
    height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 520px) and (max-height: 380px) {
      //width: 180px;
    }

    &::before {
      content: "❤";
      content: "\f004";
      font-family: "FontAwesome", sans-serif;
      text-align: center;
      display: inline-block;
      width: 30px;
      line-height: 30px;
      height: 30px;
      margin-right: 10px;
      background: @bg;
      border-radius: 100%;
    }

    &:hover {
      background: @grii;
    }

    &.router-link-exact-active {
      background: @grii;

      &::before {
        color: @crem;
      }
    }
  }

  // label {
  //   margin: 0 23px 0 23px;
  //   font-size: 54px;
  //   display: inline-block;
  //   opacity: 0.4;

  //   &:hover {
  //     opacity: 1 !important;
  //   }
  // }

  // input {
  //   display: none;

  //   &:checked + label {
  //     opacity: 0.8;
  //   }
  // }
}

.flag {
  position: fixed !important;
  height: 16vh;
  max-height: 50px;
  margin: 2vh 0vh 0px 0px;
  padding: 5px 2vh 5px 5px;
  z-index: 30;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: @bg;
  border: 2px solid @crem;
  border-right: none;
}
</style>

<i18n lang="yml" src="@/locales/Navigation.yml" />
