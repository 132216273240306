import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Auth from "@/services/Auth.js";

//import { preloadAll } from "@/services/common.js";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { auth: 0, fullscreen: true, customTransition: true },
    beforeEnter: async () => {
      return true;
    },
    component: Home,
  },
  {
    path: "/invitation",
    name: "Invitation",
    meta: { auth: 1, fullscreen: true, customTransition: true },
    beforeEnter: async () => {
      return true;
    },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Invitation.vue"),
  },
  {
    path: "/event",
    name: "Event",
    meta: { auth: 1, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Event.vue"),
  },
  {
    path: "/rsvp/:invite",
    name: "RSVP",
    meta: { auth: 1, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/RSVP.vue"),
  },
  {
    path: "/attendees",
    name: "Attendees",
    meta: { auth: 1, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Attendees.vue"),
  },
  {
    path: "/media",
    name: "Media",
    meta: { auth: 1, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Media.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { auth: 1, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Contact.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { auth: 2, fullscreen: false },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition && to.name == "Admin") {
      return {
        left: 0,
        top: Math.round(savedPosition.top),
        behavior: "smooth",
      };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from) => {
  const auth = Auth.state;
  if (!to.name) return { name: "Invitation" };
  if (to.meta.auth && !auth.logged) return { name: "Home" };
  if (to.meta.auth > 1 && !auth.admin) return { name: "Invitation" };

  to.meta.transition =
    (from.meta.customTransition ? from.name : "Default") +
    "-to-" +
    (to.meta.customTransition ? to.name : "Default");
});

export default router;
