import API from "@/services/API.js";
import { reactive } from "vue";

export default class Auth {
  static refresh_rate = 3 * 60 * 1000; // 3min
  static state = reactive({});
  static {
    this.logout();
    window.addEventListener(
      "storage",
      () => localStorage.lastID != this.state.id.hashCode() && this.logout(),
    );
    setInterval(() => {
      this.refresh();
    }, 45 * 1000);
  }

  static refresh() {
    if (!this.state.logged) return;
    if (Date.now() - this.state.ts < this.refresh_rate) return;
    this.login("refresh");
  }

  static login(code) {
    let req = API.get("auth/" + code);
    let pr = new Promise((resolve) => {
      req.then((r) => {
        localStorage.lastID = r.id.hashCode();
        Object.assign(this.state, {
          logged: true,
          admin: !!r.admin,
          id: r.id,
          ts: Date.now(),
        });
        resolve();
      });
    });
    pr.info = req.info;
    pr.abort = req.abort;
    return pr;
  }

  static logout() {
    Object.assign(this.state, { logged: false, admin: false, id: "", ts: 0 });
  }
}
