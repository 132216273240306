//import Common from '../services/Common.js'
import { reactive } from "vue";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import Auth from "@/services/Auth.js";

export default class API {
  static endpoint = process.env.VUE_APP_API_ENDPOINT.replace(
    "HOSTNAME",
    location.hostname,
  );

  static call(method, url, options = {}) {
    if (!options.decode) options.decode = "json";
    let controller = new AbortController();
    let info = reactive({ state: null, method: method });
    let pr = new Promise((resolve, reject) => {
      fetch(
        this.endpoint +
          url +
          "?" +
          new URLSearchParams(omitBy(options.query, isUndefined)),
        {
          method: method,
          body: options.body,
          credentials:
            process.env.NODE_ENV == "development" ? "include" : "same-origin",
          signal: controller.signal,
        },
      )
        //.then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 1000)))
        .then((x) => {
          if (!x.ok) throw x;
          return x[options.decode]();
        })
        .then((x) => {
          if (controller.signal.aborted)
            throw { status: 0, statusText: "Aborted" };
          info.state = true;
          resolve(x);
        })
        .catch((x) => {
          //if(x.status) Common.showNotif("HTTP "+x.status+" : "+x.statusText, "danger");
          if (x.status == 401) Auth.logout();
          if (x.status == 429)
            window.UIkit.modal.alert(
              "Too many attempts! You can try again in 1 hour.<br>Prea multe încercări! Puteți încerca din nou peste 1 oră.",
            );
          info.state = false;
          if (options.passRejection) reject(x);
        });
    });
    pr.info = info;
    pr.abort = () => {
      controller.abort();
    };
    return pr;
  }
  static get(url, options = {}) {
    return this.call("GET", url, options);
  }
  static put(url, options = {}) {
    return this.call("PUT", url, options);
  }
  static delete(url, options = {}) {
    return this.call("DELETE", url, options);
  }
  static post(url, options = {}) {
    return this.call("POST", url, options);
  }
  static blank() {
    return { info: { method: "GET", state: true }, abort: () => {} };
  }
}
