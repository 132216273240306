<template>
  <div>
    <div class="uk-flex uk-flex-center uk-flex-middle">
      <div>
        <form
          class="uk-card uk-card-body"
          autocomplete="off"
          @submit.prevent="checkCode()"
        >
          <StateLabel :info="req.info" />
          <h1 class="euk-heading-line uk-text-center">
            <span>{{ $t("welcome") }}</span>
          </h1>
          <p>{{ $t("text") }}</p>
          <div class="uk-margin">
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon" uk-icon="icon: hashtag"></span>
              <input
                ref="input"
                v-model="code"
                class="uk-input"
                :placeholder="$t('code')"
                type="text"
                :disabled="req.info.state == null"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="uk-margin uk-align-right test">
            <button class="uk-button uk-button-default" type="submit">
              {{ $t("enter") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/API.js";
import StateLabel from "@/components/StateLabel.vue";
import { preloadForPath } from "@/services/common.js";
import Auth from "@/services/Auth.js";

export default {
  name: "Home",
  components: { StateLabel },
  data() {
    return {
      code: "",
      req: API.blank(),
    };
  },
  mounted() {
    preloadForPath("/invitation");
  },
  methods: {
    async checkCode() {
      if (this.req.info.state == null) return;
      this.$refs.input.blur();
      this.code = this.code.trim().toLowerCase();
      this.req = Auth.login(this.code);
      await this.req;
      this.req.info.state = null;
      await preloadForPath("/invitation");
      this.req.info.state = true;
      await this.$router.push(this.$route.redirectedFrom || "/invitation");
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  font-family: "Lavishly Yours", cursive;
}

.uk-input {
  border-radius: 25px;
}

.uk-button {
  font-family: "Segoe Print", cursive;
  background: #d9d9d9 !important;
}

p {
  font-family: "Segoe Print", cursive;
}

#content {
  overflow: auto;
  background-image: url("~@/assets/backgrounds/left.webp"),
    url("~@/assets/backgrounds/fill.webp");
  background-repeat: no-repeat, repeat;
  background-size:
    auto 100vh,
    auto 100vh;
  background-position: left, right;
  padding-left: 30vh;

  > div {
    height: 100%;
  }

  @media (max-width: 100vh) {
    background-image: url("~@/assets/backgrounds/top.webp"),
      url("~@/assets/backgrounds/fill.webp");
    background-repeat: no-repeat, repeat;
    background-size:
      100vw auto,
      100vw auto;
    background-position: top, bottom;
    padding-left: 0;
    padding-top: 50vw;

    > div {
      height: calc(100vh - 50vw);
    }
  }
}

input {
  text-transform: uppercase;
}
</style>

<i18n lang="yml" src="@/locales/Home.yml" />
