<template>
  <div class="uk-card-badge uk-label" :class="stateText">
    {{ $t(stateText) }}
  </div>
</template>

<script>
export default {
  name: "StateLabel",
  props: ["info"],
  computed: {
    stateText() {
      if (this.info == null || this.info == undefined)
        return alert("SL: null error");

      if (this.info.state === true)
        return this.info.method == "GET" ? "loaded" : "saved";
      if (this.info.state === false) return "error";
      return "loading";
    },
  },
};
</script>

<style scoped lang="less">
.uk-label {
  border-radius: 0px 10px;
  line-height: 22px;
  text-transform: uppercase;
  opacity: 1;
  width: 100px;
  text-align: center;
  background: transparent;
  border: black 1px solid;
  pointer-events: none;
}

.loaded {
  opacity: 0;
}

.saved {
  transition: opacity 2s linear 2s;
  opacity: 0;
}
</style>

<i18n lang="yml" src="@/locales/StateLabel.yml" />
